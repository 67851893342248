import React, { useState } from 'react';

const TextArea = ({ name, label, className, onChange }) => (
  <div className={className}>
    <div className="mt-1">
      <textarea
        onChange={onChange}
        id={name}
        name={name}
        rows="4"
        placeholder={label}
        aria-label={label}
        className="py-3 px-4 block w-full rounded-sm shadow appearance-none ring-0 focus:outline-none focus:ring-2 focus:ring-sp-primary-green"
      />
    </div>
  </div>
);

export default TextArea;
