import React from 'react';
import { navigate } from 'gatsby';

import ArrowRight from '../svg/ArrowRight';
import useContactForm from '../../hooks/useContactForm';
import TextField from '../TextField';
import TextArea from '../TextArea';
import RecaptchaField from '../RecaptchaField';

const DemoFormSection = () => {
  const { data, handleChange, handleRecaptcha, handleSubmit } = useContactForm(() => {
    navigate('/thank-you-request-demo');
  });

  return (
    <section className="relative">
      <div className="absolute h-full w-full flex">
        <div className="bg-gray-200 h-full w-full">&nbsp;</div>
        <div className="bg-gray-200 lg:bg-sp-primary-dark h-full w-full">&nbsp;</div>
      </div>
      <div className="relative max-w-screen-xl mx-auto ">
        <div className="max-w-7xl mx-auto py-20 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="w-full md:max-w-lg mx-auto lg:pr-12">
              <h2 className="text-4xl text-center mb-8">Request Demo</h2>
              <p className="text-lg text-center mb-10">
                Interested in receiving an interactive demo of the Subscribe Pro platform? Please send us your contact information
                here or give us a call at 1-888-918-3569.
              </p>
              <form
                onSubmit={handleSubmit}
                method="POST"
                name="demo"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                data-netlify-recaptcha="true"
              >
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                  <TextField
                    value={data['bot-field']}
                    onChange={handleChange}
                    name="bot-field"
                    label="Bot Field"
                    className="hidden"
                  />
                  <TextField value={data['first-name']} onChange={handleChange} name="first-name" label="First name" />
                  <TextField value={data['last-name']} onChange={handleChange} name="last-name" label="Last name" />
                  <TextField
                    type="email"
                    value={data.email}
                    onChange={handleChange}
                    name="email"
                    label="Email"
                    className="sm:col-span-2"
                  />
                  <TextField
                    value={data.website}
                    onChange={handleChange}
                    name="website"
                    label="Your Website"
                    className="sm:col-span-2"
                  />
                  <TextArea
                    value={data.message}
                    onChange={handleChange}
                    name="message"
                    label="Message"
                    className="sm:col-span-2"
                  />
                  <RecaptchaField handleRecaptcha={handleRecaptcha} className="sm:col-span-2" />
                </div>
                <div className="mt-8">
                  <button type="submit" className="w-full btn btn-lg btn-sp-green flex justify-center">
                    <span className="block">Let&apos;s talk</span>
                    <ArrowRight className="ml-5" />
                  </button>
                </div>
              </form>
            </div>

            <div className="hidden lg:block w-full md:max-w-lg mx-auto pl-8">&nbsp;</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemoFormSection;
