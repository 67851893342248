import * as React from 'react';
import { Link } from '@reach/router';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import TopBrandsSection from '../components/sections/TopBrandsSection';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import HeroGraphic from '../components/svg/HeroGraphic';
import Container from '../components/Container';
import DemoFormSection from '../components/sections/DemoFormSection';

const RequestDemoPage = () => (
  <Layout>
    <Seo title="Request Demo" />

    <header className="relative bg-sp-primary background-hero sm:pb-7">
      <Container>
        <div className="flex flex-col lg:flex-row items-center md:px-10 lg:px-0 py-20 lg:py-0">
          <div className="max-w-[48rem] text-center md:text-left mr-auto">
            <h1 className="text-5xl md:text-6xl lg:text-6xl text-white mb-10 sm:mb-8 px-12 sm:px-0">
              Request a Demo of
              <br />
              <span className="text-sp-text-black">Subscribe Pro </span>
              <br />
            </h1>
            <p className="text-2xl md:text-2xl lg:text-3xl text-white px-5 md:px-0 mb-10 sm:mb-8">
              We keep your orders recurring. And our mature subscription e-commerce technology provides a stable platform you can
              build on.
            </p>
          </div>
          <div className="md:mr-11">
            <HeroGraphic className="hidden xl:block w-full lg:my-10" size="lg" />
            <HeroGraphic className="hidden lg:block xl:hidden w-full lg:my-10" size="md" />
            <HeroGraphic className="hidden md:block lg:hidden w-full mt-12" size="lg" />
            <HeroGraphic className="hidden sm:block md:hidden w-full mt-12" size="md" />
            <HeroGraphic className="block sm:hidden w-full mt-12" size="sm" />
          </div>
        </div>
      </Container>
    </header>

    <DemoFormSection />

    <TopBrandsSection containerClassName="max-w-screen-lg" showOneLineOnly showCards={false} />

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default RequestDemoPage;
